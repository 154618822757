.App {
    width: 100vw;
    min-height: 100vh;

    .Header {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;

        .HeaderTop {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 0;

            img {
                height: 60px;
            }
        }

        .HeaderBottom {
            width: calc(100% - 100px);
            padding: 20px 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #2c2c2c;
            background-color: #d43434;
            color: white;
            font-family: Montserrat, sans-serif;
            font-weight: 400;

            .HeaderTypes {
                margin: auto;
                display: flex;
                flex-direction: row;

                .HType+.HType {
                    margin-left: 80px;
                }

                .HType {
                    transition: all 0.1s linear;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }

                    &:active {
                        transform: scale(0.98);
                    }
                }

                .Lined {
                    text-decoration: underline;
                }
            }
        }
    }

    .Content {
        width: 100%;
        min-height: calc(100vh - 139px);
    }

    .Footer {
        width: 100%;
        background-color: #182443;

        .FooterTop {
            width: calc(100% - 100px);
            padding: 20px 50px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            color: white;
            font-family: Montserrat, sans-serif;

            .FWrap1,
            .FWrap2 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .FWrap1 {
                width: 42%;
            }

            .FWrap2 {
                width: 55%;
            }

            .FLogo {
                width: 150px;

                img {
                    width: 150px;
                }
            }

            .FForm {
                width: 300px;

                .FFSub {
                    font-size: 18px;
                    font-weight: 500;
                }

                .InputName {
                    width: calc(100% - 30px);
                    font-size: 16px;
                    font-family: Montserrat, sans-serif;
                    padding: 12px 15px;
                    margin: 10px 0 0 0;
                    border: none;
                    border-radius: 5px;
                    outline: none;
                }

                .InputContainer {
                    margin-top: 10px;
                    padding: 2px 15px;
                    background-color: white;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 5px;

                    .PreNum {
                        display: flex;
                        align-items: center;
                        color: #2c2c2c;
                    }

                    .InputNumber {
                        width: calc(100% - 20px);
                        height: calc(100% - 20px);
                        padding: 10px 0;
                        border: none;
                        outline: none;
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 400;
                        padding: 10px 0;
                        color: #2c2c2c;
                    }
                }

                .FSubmit {
                    width: calc(100% - 40px);
                    padding: 12px 20px;
                    margin-top: 10px;
                    background-color: #7A1A1A;
                    background-color: #585858;
                    border-radius: 5px;
                    text-align: center;
                    font-weight: 500;
                    transition: background-color 0.1s linear, opacity 0.1s linear, transform 0.1s linear;
                }

                .SubmitRed {
                    background-color: #7A1A1A;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }

                    &:active {
                        transform: scale(0.99);
                    }
                }
            }

            .FRevs {
                width: 270px;
                display: flex;
                flex-direction: column;
                font-size: 16px;
                font-weight: 500;

                .FRev {
                    margin-top: 20px;
                    width: calc(270px - 10px);
                    background-color: white;
                    color: #2c2c2c;
                    border-radius: 5px;
                    padding: 5px;
                    text-decoration: unset;
                    transition: opacity 0.1s linear, transform 0.1s linear;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    img {
                        height: 40px;
                        border-radius: 5px;
                    }

                    div {
                        margin-left: 10px;
                    }

                    &:hover {
                        opacity: 0.8;
                    }

                    &:active {
                        transform: scale(0.99);
                    }
                }

                .FRev+.FRev {
                    margin-top: 10px;
                }

                .FMap {
                    width: 270px;
                    height: 200px;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: none;

                }
            }

            .FLinks {

                width: 325px;

                .FLSub {
                    font-size: 18px;
                    font-weight: 500;
                }

                .FLNum {
                    margin-top: 10px;

                    a {
                        text-decoration: unset;

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }

                .FLMedia {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;

                    a+a {
                        margin-left: 15px;
                    }

                    img {
                        width: 40px;
                    }
                }
            }
        }

        .FooterBottom {
            width: 100%;
            text-align: center;
            padding: 20px 0;
            color: white;
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            opacity: 0.6;
        }
    }

    .MinuteForm {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 11;
        background-color: rgba(0, 0, 0, 0.6);
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        color: #2c2c2c;

        .MContainer {
            width: 800px;
            // height: 100px;
            padding: 20px;
            background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            .CloseForm {
                position: absolute;
                right: 20px;
                top: 20px;
                cursor: pointer;
            }

            .MImg {
                width: 39%;
                display: flex;

                img {
                    width: 100%;
                }
            }

            .MContent {
                width: 59%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .MSub {
                text-align: center;
                font-size: 20px;
                font-weight: 500;
            }

            .InputContainer {
                margin-top: 30px;
                padding: 2px 15px;
                background-color: white;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-radius: 5px;
                border: solid 1px #2c2c2c;

                .PreNum {
                    display: flex;
                    align-items: center;
                    color: #2c2c2c;
                }

                .InputNumber {
                    width: calc(100% - 20px);
                    height: calc(100% - 20px);
                    padding: 10px 0;
                    border: none;
                    outline: none;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    padding: 10px 0;
                    color: #2c2c2c;
                }
            }

            .FSubmit {
                width: calc(100% - 40px);
                padding: 12px 20px;
                margin-top: 10px;
                background-color: #585858;
                color: white;
                border-radius: 5px;
                text-align: center;
                font-weight: 500;
                transition: background-color 0.1s linear, opacity 0.1s linear, transform 0.1s linear;
            }

            .SubmitRed {
                background-color: #d43434;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                &:active {
                    transform: scale(0.99);
                }
            }
        }
    }
}

.FLine {
    display: none;
}

@media (max-width: 1250px) {
    .FooterTop {
        flex-direction: column !important;
    }

    .FWrap1,
    .FWrap2 {
        width: 100% !important;
    }

    .FWrap2 {
        flex-direction: row-reverse !important;
    }

    .FRevs {
        width: 325px !important;
    }

    .FRev {
        width: calc(325px - 10px) !important;
    }

    .FMap {
        width: 325px !important;
    }

    .FForm {
        width: 325px !important;
    }

    .FLogo {
        width: calc(100% - 325px) !important;
    }

    .FRevs,
    .FLinks {
        margin-top: 30px;
    }
}

@media (max-width: 670px) {
    .Content {
        min-height: calc(100vh - 200px) !important;
    }

    .HeaderTypes {
        flex-direction: column !important;
        width: 100% !important;
    }

    .HeaderBottom {
        padding: 0 50px !important;
        padding: 0 !important;
        width: 100% !important;
    }

    .HType {
        width: 100% !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding: 10px 0 !important;
    }

    .Lined {
        text-decoration: unset !important;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .FWrap1 {
        flex-direction: column !important;
    }

    .FWrap2 {
        flex-direction: column !important;
    }

    .FLine {
        display: block !important;
        margin-top: 30px;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
    }

    .FForm {
        margin-top: 20px !important;
    }

    .MContainer {
        flex-direction: column !important;
        overflow: hidden;
    }

    .CloseForm {
        background-color: white;
        border-radius: 100px;
        right: 5px !important;
        top: 5px !important;
    }

    .MImg {
        display: block !important;
        width: 100% !important;
        height: 300px !important;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .MContent {
        width: 100% !important;
    }
}

@media (max-width: 700px) {
    .FooterTop {
        padding: 20px 30px !important;
        width: calc(100% - 60px) !important;
    }
}

@media (max-width: 470px) {
    .FForm {
        width: 100% !important;
    }

    .FRevs {
        width: 100% !important;
    }

    .FMap {
        width: 100% !important;
    }

    .FRev {
        width: calc(100% - 10px) !important;
    }

    .FLinks {
        width: 100% !important;
    }
}

@media (max-width: 600px) {
    .FooterTop {
        flex-direction: column;
    }

    .FLogo {
        width: 100% !important;
    }

    .FRevs {
        width: 100% !important;
    }

    .FForm {
        margin-top: 30px;
    }

    .FLine {
        margin-top: 30px;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
    }
}

@media (max-width: 430px) {
    .FooterBottom {
        font-size: 12px !important;
    }
}

@media (max-width: 380px) {
    .FooterBottom {
        font-size: 11px !important;
    }
}

@media (max-width: 340px) {
    .FooterBottom {
        font-size: 10px !important;
    }
}