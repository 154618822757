.SliderContainer {
    width: 100%;
}

.slider {
    width: 100%;
    height: 3px;
    background: #2c2c2c;
    border-radius: 5px;
    margin: 20px 0;
    position: relative;

    .LeftTrack {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #ccc;
        border-radius: 5px;
    }

    .RightTrack {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        background-color: #ccc;
        border-radius: 5px;
    }
}

.slider .thumb {
    width: 15px;
    height: 15px;
    background-color: #2c2c2c;
    outline: none;
    cursor: pointer;
    top: calc(50% - 7.5px);
}

.PriceValues {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
}