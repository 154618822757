.GripChecker {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .GripCheckBox {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border: solid 1px #2c2c2c;
        cursor: pointer;
    }

    .Checked {
        background-color: #2c2c2c;
    }
}