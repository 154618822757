.AdminContainer {
    min-height: calc(100vh - 80px - 60px);
    width: calc(100%);
    padding: 30px 0;
    font-family: Montserrat, sans-serif;
    display: flex;
    color: #2c2c2c;
    flex-direction: column;
    align-items: center;

    .AdminSub {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
    }

    .AdminCheck {
        .CheckInput {
            width: 250px;
            margin-top: 10px;
            padding: 10px 20px;
            border: solid 1px #2c2c2c;
            outline: none;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
        }

        .CheckNext {
            margin-top: 10px;
            background-color: #182443;
            width: 250px;
            padding: 10px 20px;
            color: white;
            text-align: center;
            transition: all 0.1s linear;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            &:active {
                transform: scale(0.98);
            }
        }

        .CheckWrong {
            margin-top: 10px;
            text-align: center;
            color: #d43434;
            display: none;
        }

        .Error {
            display: block;
        }
    }

    .Panel {
        margin-top: 30px;
        width: calc(100% - 100px);
        min-height: 700px;
        background-color: #2c2c2c;
        border: solid 1px #2c2c2c;
        color: white;

        .PanelHead {
            display: flex;
            flex-direction: row;

            .PanelTab {
                padding: 10px 20px;
                transition: all 0.1s linear;
                cursor: pointer;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }

        .Workspace {
            padding: 20px;

            .CreateBtn {
                background-color: white;
                width: fit-content;
                color: #2c2c2c;
                padding: 5px 10px;
                margin-left: 2px;
                transition: opacity 0.1s linear, transform 0.1s linear;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                &:active {
                    transform: scale(0.98);
                }
            }

            .DeleteContainer {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .DeleteBtn {
                    background-color: #d43434;
                    width: fit-content;
                    color: white;
                    padding: 5px 10px;
                    margin-left: 2px;
                    margin-right: 15px;
                    transition: opacity 0.1s linear, transform 0.1s linear;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }

                    &:active {
                        transform: scale(0.98);
                    }
                }

                .ThrowCheck {
                    text-decoration: underline;
                    transition: opacity 0.1s linear, transform 0.1s linear;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }

                    &:active {
                        transform: scale(0.98);
                    }
                }
            }

            .DeleteConfirm {
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                input {
                    width: 166px;
                    margin-top: 10px;
                    margin-left: 2px;
                    padding: 10px 20px;
                    border: none;
                    outline: none;
                    font-family: Montserrat, sans-serif;
                    font-size: 16px;
                }

                .ConfirmText {
                    font-weight: 500;
                }

                .ConfirmBtns {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;

                    .DeleteBtn {
                        background-color: #d43434;
                        width: fit-content;
                        color: white;
                        padding: 5px 10px;
                        margin-left: 2px;
                        margin-right: 15px;
                        transition: opacity 0.1s linear, transform 0.1s linear;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }

                        &:active {
                            transform: scale(0.98);
                        }
                    }

                    .DeleteCancel {
                        background-color: #636363;
                        width: fit-content;
                        color: white;
                        padding: 5px 10px;
                        margin-left: 2px;
                        margin-right: 15px;
                        transition: opacity 0.1s linear, transform 0.1s linear;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }

                        &:active {
                            transform: scale(0.98);
                        }
                    }
                }

            }

            .Clue {
                margin-top: 20px;
                font-size: 14px;
                opacity: 0.7;
                margin-left: 2px;
            }

            .NoItems {
                margin-top: 20px;
            }

            .TableWrap {
                table {
                    margin-top: 5px;
                    color: #2c2c2c;
                    background-color: white;
                    background-color: #2c2c2c4f;

                    tbody {
                        tr {

                            cursor: pointer;

                            &:nth-child(odd) {
                                background-color: white;
                            }

                            &:nth-child(even) {
                                background-color: #e7e9eb;
                            }

                            &:hover {
                                background-color: rgb(160, 223, 137);
                                background-color: rgb(151, 173, 230);
                            }

                            th {
                                padding: 8px;
                                text-align: left;
                                font-weight: 600;
                                background-color: white;
                                cursor: auto;
                            }

                            td {
                                padding: 8px;
                                text-align: left;
                                font-weight: 500;
                            }

                            .Checkbox {
                                cursor: pointer;
                            }

                            .DeleteRow {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &:hover {
                                    background-color: rgb(223, 137, 137);
                                    background-color: #d43434;
                                    color: white;
                                }
                            }

                            .ItemName {
                                display: flex;
                                flex-direction: column;

                                .Code {
                                    margin-top: 5px;
                                    font-size: 14px;
                                    opacity: 0.6;
                                }
                            }
                        }
                    }
                }
            }

            .CreateContainer {
                margin-top: 20px;
                margin-left: 2px;
                display: flex;
                flex-direction: column;
                max-width: 400px;

                .CreateSub {
                    font-size: 20px;
                    font-weight: 500;
                }

                .InputClue {
                    font-size: 12px;
                    opacity: 0.7;
                    margin-top: 10px;
                    margin-bottom: 2px;
                }

                input,
                textarea {
                    padding: 10px 20px;
                    width: calc(100% - 40px);
                    outline: none;
                    border: unset;
                    font-size: 16px;
                    font-family: Montserrat, sans-serif;
                }

                textarea {
                    margin-left: 0 !important;
                    resize: vertical;
                    max-height: 200px;
                }

                .FileInput {
                    width: calc(100% - 2px);
                    height: 100px;
                    border: dashed 1px white;
                    position: relative;
                    transition: background-color 0.1s linear;

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                    }

                    .FileInfo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: none;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .FileText {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .FileTextLoad {
                                margin-left: 5px;
                            }
                        }

                        .FileClue {
                            font-size: 12px;
                            opacity: 0.7;
                            margin-top: 5px;
                            padding: 0 10px;
                            text-align: center;
                        }
                    }

                    .Showed {
                        display: flex;
                    }

                    input {
                        z-index: 5;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        padding: 0 !important;
                        margin: 0 !important;
                        opacity: 0;
                        cursor: pointer;
                    }
                }

                .FileClear {
                    font-size: 14px;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-bottom: 10px;
                    margin-top: 5px;
                    display: none;
                }

                .FileClear.Showed {
                    display: block;
                }

                .CreateWarning {
                    color: #d43434;
                    margin-top: 10px;
                    display: none;
                }

                .CreateWarning.Error {
                    display: block;
                }

                .CreateItemBtn {
                    margin-top: 10px;
                    width: calc(100% - 40px);
                    padding: 10px 20px;
                    text-align: center;
                    font-weight: 500;
                    background-color: forestgreen;
                    transition: opacity 0.1s linear;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .CreateCancelItemBtn {
                    margin-top: 10px;
                    width: calc(100% - 40px);
                    padding: 10px 20px;
                    text-align: center;
                    font-weight: 500;
                    background-color: rgb(139, 34, 34);
                    transition: opacity 0.1s linear;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .PhotoChange {
                    width: 100%;

                    .ChangeImgContainer {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        img {
                            width: calc(100% / 3 - 4px);
                        }
                    }

                    .ArrayImg {
                        margin-left: -5px;
                        width: calc(100% + 5px);

                        .ImgChange {
                            width: calc(100% / 3 - 5px);
                            margin-left: 5px;
                            margin-bottom: 5px;
                            display: flex;
                            position: relative;

                            img {
                                width: 100%;
                            }

                            .ImgChangeDelete {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(0, 0, 0, 0.4);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                opacity: 0;
                                transition: opacity 0.1s linear, background-color 0.1s linear;
                                cursor: pointer;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }

                        .ImgChange.ChooseImg {
                            .ImgChangeDelete {
                                background-color: rgba(139, 34, 34, 0.8);
                                opacity: 1;
                            }
                        }
                    }
                }

                .PhChange2 {
                    margin-bottom: -5px;
                }
            }
        }
    }

    .CreateNewModal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;

        .ModalBox {
            width: 400px;
            height: 100px;
            background-color: white;
        }
    }
}

.TableClue.Light {
    color: white;
    margin-left: 2px;
    margin-top: 10px !important;
    display: none !important;
}

.Chosen {
    background-color: white !important;
    color: #2c2c2c;
}

.TableInput {
    max-width: calc(400px - 2px);
    height: 100px;
    border: dashed 1px white;
    position: relative;
    transition: background-color 0.1s linear;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .FileInfo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .FileText {
            display: flex;
            flex-direction: row;
            align-items: center;

            .FileTextLoad {
                margin-left: 5px;
            }
        }

        .FileClue {
            font-size: 12px;
            opacity: 0.7;
            margin-top: 5px;
            padding: 0 10px;
            text-align: center;
        }
    }

    .Showed {
        display: flex;
    }

    input {
        z-index: 5;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
        opacity: 0;
        cursor: pointer;
    }
}

.FileClear {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 5px;
    display: none;
}

.FileClear.Showed {
    display: block;
}

.UploadBtn {
    margin-top: 10px;
    max-width: calc(400px - 40px);
    padding: 10px 20px;
    text-align: center;
    font-weight: 500;
    background-color: #636363;
    transition: opacity 0.1s linear, background-color 0.1s linear;
}

.UploadActive {
    background-color: forestgreen;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.Uploading {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        margin-left: 5px;
        font-size: 14px;
    }
}

.Success {
    color: forestgreen;
}

.TableXLClue {
    font-size: 13px;
    opacity: 0.7;
    margin-top: 10px;
    margin-bottom: 2px;
    max-width: 884px;
    line-height: 18px;
}

.TableWrap3 {
    table {
        margin-top: 5px;
        color: #2c2c2c;
        background-color: white;
        background-color: #2c2c2c4f;
        font-size: 14px;

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: white;
                }

                &:nth-child(even) {
                    background-color: #e7e9eb;
                }

                th {
                    padding: 5px;
                    text-align: left;
                    font-weight: 600;
                    background-color: white;
                    cursor: auto;
                }

                td {
                    padding: 5px;
                    text-align: left;
                    font-weight: 500;
                }
            }
        }
    }
}

@media (max-width: 1020px) {
    .TableWrap3 {
        overflow-x: scroll !important;
    }
}

@media (max-width: 970px) {
    .TableWrap {
        overflow-x: scroll !important;
    }

    .Panel {
        width: calc(100% - 20px) !important;
    }
}

@media (max-width: 790px) {
    .TableClue.Light {
        display: block !important;
    }
}

@media (max-width: 550px) {
    .PanelHead {
        flex-direction: column !important;
        border-bottom: solid 1px rgba(255, 255, 255, 0.3);
    }

    .PanelTab {
        background-color: unset !important;
    }

    .Chosen {
        background-color: rgba(255, 255, 255, 0.1) !important;
        color: white !important;
    }
}