.Catalogue {
    width: calc(100% - 100px);
    padding: 30px 50px;
    font-family: Montserrat, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ShowFilter {
        display: none;
        flex-direction: row;
        align-items: center;
        color: #2c2c2c;
        font-weight: 600;
        margin-top: -20px;
        padding: 20px 0;

        img {
            height: 25px;
            margin-right: 15px;
        }

        span {
            margin-right: 5px;
        }

        .Down {
            transform: rotate(180deg);
        }
    }

    .FilterLine {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.2);
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        padding: 0 !important;
    }

    .FiltersBox {
        .Filter {
            border: solid 1px rgba(0, 0, 0, 0.2);
            background-color: white;
            color: #2c2c2c;
            width: 250px;

            .FSub {
                padding: 10px;
                font-size: 18px;
                font-weight: 600;
                border-bottom: solid 1px rgba(0, 0, 0, 0.2);
            }

            .FilterBox {
                padding: 10px;
                border-bottom: solid 1px rgba(0, 0, 0, 0.2);
                position: relative;
                overflow: hidden;
                user-select: none;

                .FBSub {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;

                    .Arrow {
                        margin-left: 5px;
                    }

                    .Down {
                        transform: rotate(180deg);
                    }
                }

                .Invisible {
                    transform: translateY(-10000px);
                    position: absolute;
                    width: calc(100% - 20px);
                }
            }

            .LastBox {
                border-bottom: none;
            }
        }

        .FilterFetch {
            margin-top: 10px;
            width: calc(250px - 40px);
            padding: 10px 20px;
            border: solid 1px #182443;
            color: #182443;
            background-color: white;
            font-size: 14px;
            text-align: center;
            transition: background-color 0.1s linear, color 0.1s linear, transform 0.1s linear;
            cursor: pointer;

            &:hover {
                background-color: #182443;
                color: white;
            }

            &:active {
                transform: scale(0.98);
            }
        }

        .FilterBreak {
            margin-top: 10px;
            width: calc(250px - 40px);
            padding: 10px 20px;
            border: solid 1px #d43434;
            color: #d43434;
            background-color: white;
            font-size: 14px;
            text-align: center;
            transition: background-color 0.1s linear, color 0.1s linear, transform 0.1s linear;
            cursor: pointer;

            &:hover {
                background-color: #d43434;
                color: white;
            }

            &:active {
                transform: scale(0.98);
            }
        }
    }

    .ItemsBox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% - 300px);

        .ItemsBoxShow {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;

            .Pagination {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .PageBtn {
                    padding: 0 15px;
                    cursor: pointer;
                }
            }
        }

        .ItemCard {
            margin-left: calc(10% / 4);
            background-color: #2c2c2c15;
            border: solid 1px #2c2c2c15;
            color: #2c2c2c;
            width: 30%;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .ItemImg {
                width: 100%;
                height: fit-content;
                position: relative;

                img {
                    width: 100%;
                }

                .ItemClick {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: #2c2c2cb2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    transition: all 0.1s linear;
                    cursor: pointer;

                    &:hover {
                        opacity: 1;
                    }

                    .ItemShow {
                        padding: 10px 20px;
                        color: white;
                        border: solid 1px white;
                        transition: all 0.1s linear;
                        cursor: pointer;

                        &:hover {
                            background-color: white;
                            color: #2c2c2c;
                        }

                        &:active {
                            transform: scale(0.98);
                        }
                    }
                }
            }

            .NoneImg {
                width: 100%;
                min-height: 150px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ccc;
            }

            .ItemInfo {
                padding: 10px 0;

                .ItemBrand {
                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                }

                .ItemName {
                    margin-top: 10px;
                    text-align: center;
                    font-weight: 400;
                    font-size: 18px;
                }

                .ItemPrice {
                    margin-top: 10px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }

        .NothingFound {
            width: 100%;
            text-align: center;
        }
    }

    .RestoredItemsBox {
        display: flex;
        flex-direction: column;
        width: calc(100% - 300px);

        .TableWrap2 {
            display: flex;
            flex-direction: column;
            width: 100% !important;

            table {
                color: #2c2c2c;
                background-color: white;
                background-color: #2c2c2c4f;

                tbody {
                    tr {
                        &:nth-child(odd) {
                            background-color: white;
                        }

                        &:nth-child(even) {
                            background-color: #e7e9eb;
                        }

                        th {
                            padding: 8px;
                            text-align: left;
                            font-weight: 600;
                        }

                        td {
                            padding: 8px;
                            text-align: left;
                        }

                        .ItemName {
                            display: flex;
                            flex-direction: column;

                            .Code {
                                margin-top: 5px;
                                font-size: 14px;
                                opacity: 0.6;
                            }
                        }

                        .ItemPrice {
                            min-width: 65px;
                        }

                        .ItemBuy {
                            div {
                                padding: 10px;
                                background-color: #d43434;
                                color: white;
                                text-align: center;
                                transition: all 0.1s linear;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.7;
                                }

                                &:active {
                                    transform: scale(0.98);
                                }
                            }
                        }
                    }
                }
            }
        }

        .NothingFound {
            width: 100%;
            text-align: center;
        }
    }
}

.TableClue {
    display: none;
    color: #2c2c2c;
    font-size: 14px;
    opacity: 0.7;
}

.E-pagination {
    margin: 43px 0 29px 0;
    color: #757575;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.E-pagination button {
    padding: 0;
    height: 43px;
    width: 43px;
    border-radius: 50px;
    border: solid 2px #2B788B;
    border: solid 2px #182443;
    color: #2B788B;
    color: #182443;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.Hide-1-2,
.Hide-29-30 {
    height: 43px;
    display: flex;
    align-items: flex-end;
}

.E-pagination * {
    height: 43px;
    margin-left: 14.4px;
}

.E-margin-0 {
    margin: 0;
}

.E-left {
    transform: rotate(180deg);
}

.E-this {
    background-color: #2B788B;
    background-color: #182443;
    color: white !important;
}

.E-inactive {
    border: solid 2px #757575 !important;
    color: #757575 !important;
    pointer-events: none;
}

.Removed {
    display: none !important;
}

.BuyModal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    .BuyForm {
        width: 500px;
        padding: 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        .BuySub {
            font-size: 20px;
            font-weight: 600;
        }

        .BuyClue {
            margin-top: 20px;
            width: 100%;
            font-size: 14px;
            opacity: 0.7;
        }

        .InputName {
            width: calc(100% - 32px);
            margin: 5px 0 0 0;
            padding: 10px 15px;
            border: solid 1px #2c2c2c;
            outline: none;
            font-family: Montserrat;
            font-size: 16px;
        }

        .InputContainer {
            width: calc(100% - 32px);
            margin-top: 5px;
            padding: 0 15px;
            border: solid 1px #2c2c2c;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .PreNum {
                display: flex;
                align-items: center;
            }

            .InputNumber {
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                padding: 10px 0;
                border: none;
                outline: none;
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                padding: 10px 0;
                color: #2c2c2c;
            }
        }

        .BuyInfo {
            margin-top: 5px;
            width: 100%;

            span {
                font-size: 14px;
                opacity: 0.7;
            }
        }

        .BuyCost {
            margin-top: 20px;
            font-weight: 600;
        }

        .BuyConfirmBtn {
            margin-top: 20px;
            width: calc(100% - 40px);
            padding: 11px 20px;
            background-color: #8c8c8c;
            color: white;
            text-align: center;
            font-weight: 500;
            transition: opacity 0.1s linear, transform 0.1s linear, background-color 0.1s linear;
        }

        .BuyConfirmActive {
            background-color: #182443 !important;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }

            &:active {
                transform: scale(0.99);
            }
        }
    }
}

@media (max-width: 1120px) {
    .TableWrap2 {
        overflow-x: scroll !important;
    }
}

@media (max-width: 1050px) {
    .TableClue {
        display: block;
    }
}

@media (max-width: 1000px) {
    .ItemCardMain {
        width: 48% !important;
        margin-left: 1% !important;
    }
}

@media (max-width: 870px) {
    .ShowFilter {
        display: flex !important;
    }

    .FiltersNone {
        display: none !important;
    }

    .FiltersMobile {
        margin-top: 30px !important;
    }

    .Catalogue {
        flex-direction: column !important;
    }

    .FiltersBox {
        padding-left: 2.5% !important;
        padding-right: 2% !important;
    }

    .Filter {
        width: 100% !important;
    }

    .FilterFetch {
        width: calc(100% - 40px) !important;
    }

    .FilterBreak {
        width: calc(100% - 40px) !important;
    }

    .ItemsBox {
        width: 100% !important;
        margin-top: 30px !important;
    }

    .ItemCardMain {
        width: 30% !important;
        margin-left: 2.5% !important;
    }

    .ItemClick {
        display: none !important;
    }

    .RestoredItemsBox {
        width: 100% !important;
    }

    .TableWrap2 {
        margin-top: 30px !important;
        overflow-x: unset !important;
    }

    .TableClue {
        display: none;
    }

    .NothingFound {
        margin-top: 20px !important;
    }
}

@media (max-width: 790px) {
    .TableWrap2 {
        overflow-x: scroll !important;
        margin-top: 5px !important;
    }

    .TableClue {
        margin-top: 30px;
        display: block;
    }
}

@media (max-width: 700px) {
    .Catalogue {
        padding: 30px 20px !important;
        width: calc(100% - 40px) !important;
    }
}

@media (max-width: 590px) {
    .FiltersBox {
        padding-left: 1% !important;
        padding-right: 1.5% !important;
    }

    .ItemCardMain {
        width: 48% !important;
        margin-left: 1% !important;
    }
}